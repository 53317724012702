
.profile-pic{
  height: 4vmin;
  margin-right: 10px;
  border-radius: 50px;
}

a {
  color: #343a40;
}

a:hover{
  color: #173798;
  text-decoration: none;
}

div.Media {
  margin-top: 30px;
  margin-bottom: 10px;
}

div.media .media-heading .jobTotalDuration{
  color: #666;
  font-size: 14px;
  display: block;
}

/* div.media img{
  height: 10vmin;
  margin-right: 10px;
  border-radius: 10px;
} */

.education-img-div{
  width: 15vmin;
  margin-right: 10px;
}

.education-img{
  width: 100%;
  height: 100%;
}

.cert-img-div{
  width: 15vmin;
  margin-right: 10px;
  margin-bottom: 7px;
  margin-bottom: 7px;
}

.cert-img{
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.project-img-div{
  width: 15vmin;
  margin-right: 10px;
  margin-bottom: 7px;
  margin-bottom: 7px;
}

.project-img{
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.paper-img-div{
  width: 15vmin;
  margin-right: 10px;
  margin-bottom: 7px;
  margin-bottom: 7px;
}

.paper-img{
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.job-img-div{
  width: 17vmin;
  margin-right: 10px;
}

.job-img{
  width: 100%;
  height: 100%;
}

div.media .jobDuration{
  color: #444;
  display: block;
}

div.media .jobLocation{
  color: #666;
}

.formLabel{
  font-weight: bold;
}

.certificationLink{

  color: cornflowerblue;
}
.timeline-icon{
  display: block;
  /* width: 100px;
  height: 100px; */
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  height: 100%;
}

body{
  padding-top: 90px;
}

.full-width{
  width: 100%;
}

.particles-background{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
}
footer{
  margin-top: 20px;
  margin-bottom: 10px;
  border-top: #444444;
  text-align: center;
}

footer a{
  color: black;
  font-size: 30px;
  margin-right: 10px;
  margin-left: 10px;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
} */
